import { deriveKeyNonBlocking } from "@stablelib/scrypt";
import { delay } from "./promise.ts";

export default function runKdf(
  rawPasswordBytes: Uint8Array,
  pwdSalt: Uint8Array,
  ipKeyKeySalt: Uint8Array
): Promise<Awaited<[Uint8Array, Uint8Array, void]>> {
  // OWASP-recommended values
  // https://cheatsheetseries.owasp.org/cheatsheets/Password_Storage_Cheat_Sheet.html#scrypt
  // takes about 250ms per key on M1 MBP/Chrome
  // deriveKeyNonBlocking will split work into ~100ms chunks
  const scryptParams = {
    // 32 MiB
    N: 2 ** 15,
    r: 8,
    p: 3,
  };

  // 16 bytes = 128 bits of entropy, more than enough for any eventuality
  const kdfPasswordBytesPromise = deriveKeyNonBlocking(
    rawPasswordBytes,
    pwdSalt,
    scryptParams.N,
    scryptParams.r,
    scryptParams.p,
    16
  );

  // 32 bytes is what SecretBox expects (a 256 bit long key)
  const ipKeyKeyPromise = deriveKeyNonBlocking(
    rawPasswordBytes,
    ipKeyKeySalt,
    scryptParams.N,
    scryptParams.r,
    scryptParams.p,
    32
  );

  return Promise.all([kdfPasswordBytesPromise, ipKeyKeyPromise, delay(1000)]);
}
